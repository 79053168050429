.doctor-Img {
  width: 35px;
  height: 35px;
  border-radius: 40px;
  overflow: hidden;
}

.doctor-Img img {
  width: 100%;
  height: 100%;
  object-fit: fill !important;
}
.modal-body.modal-background{
  background: #efebf9 !important;
}

.profile-pic {
  width: 44px; /* Adjust width as needed */
  height: 44px; /* Adjust height as needed */
  border-radius: 50%; /* Makes the image circular */
  object-fit: cover; /* Ensures the image covers the entire container without distortion */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow for better visuals */
}

