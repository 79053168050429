@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
/* You can add global styles to this file, and also import other style files */

.ngx-datatable.material {
  box-shadow: none !important;
}

.no-right {
  right: 0 !important;
}

.modal-dialog.modal-max {
  max-width: 85vw !important;
}

.app-sidebar .logo-img {
  width: 190px !important;
}


ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
  background-color: #d1c7c747;
  color: #605B5B;
}

/*.sidebar-logo-box {*/
/*  width: 200px;*/
/*  height: 50px;*/
/*  overflow: hidden;*/
/*}*/

/*.sidebar-logo-box img {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/

.sidebar-logo-box {
  width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.sidebar-logo-box img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ensures the image fits within the box while preserving aspect ratio */
}

.fullscreen-mode .app-sidebar.main-menu.menu-fixed.menu-native-scroll.expanded.ng-star-inserted {
  display: none !important;
}

.fullscreen-mode app-navbar {
  display: none !important;
}

.fullscreen-mode .main-content {
  margin-left: 0 !important;
}

:root {
  --primary-color: #ffff;
  --secondary-color: #ffff;
  --primary-color-organization:#6161e8;
  --secondary-color-organization:#ffffff;
}

.primary-color-organization {
  background-color: var(--primary-color-organization);
  color: #FFFFFF;
}

.secondary-color-organization {
  background-color: var(--secondary-color-organization);
  color: #000000;
}

:root {
  --primary-color: #ffff;
  --secondary-color: #ffff;
  --primary-color-organization:#6161e8;
  --secondary-color-organization:#ffffff;
}


.primary-color {
  background-color: var(--primary-color);
  color: #FFFFFF;
}

.secondary-color {
  background-color: var(--secondary-color);
  color: #000000;
}

.primary-color-organization {
  background-color: var(--primary-color-organization);
  color: #FFFFFF;
}

.secondary-color-organization {
  background-color: var(--secondary-color-organization);
  color: #000000;
}

/*.logo-img {*/
/*  width: 30px;*/
/*  height: 30px;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*}*/

.app-sidebar .logo-img {
  width: 164px !important;
  display: block;
}

.app-sidebar .logo-img img {
  width: 100% !important;
}

.pharmacy-logo-container {
  display: flex;
  overflow: hidden;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  width: 100px; /* Adjust this according to your layout */
  height: 85px; /* Adjust this according to your layout */
}

.pharmacy-logo-container img {
  width: 100%;
}

.image-container {
  width: 100%; /* Set div to take full width */
  height: 100px; /* Set a fixed height or adjust as needed */
  display: flex; /* Use flexbox for centering */
  align-items: start; /* Center vertically */
  justify-content: left; /* Center horizontally */
  overflow: hidden; /* Hide overflow if the image is larger */
}

.image-container img {
  max-width: 20%;
  max-height: 84%;
  object-fit: cover !important;
}

.sidebar-pharmacy-logo-box {
  width: 200px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.sidebar-pharmacy-logo-box img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ensures the image fits within the box while preserving aspect ratio */
}
.iti {
  width: 350px !important;
}


ngx-spinner {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: fixed; /* Ensure it's positioned relative to the viewport */
  top: 50%;
  left: 50%;
  z-index: 9999; /* Ensure it stays above other elements */
}
