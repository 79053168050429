label.btn-primary.btn.active {
  background-color: white !important;
  color: #7727ff !important;
}

.btn {
  margin-top: 5px;
  margin-bottom: 5px;
}

.no-pointer, .btn-danger {
  cursor: default !important;
}

.btn-danger:hover {
  background-color: #F55252 !important;
  box-shadow: none !important;
}

.btn-dark:hover {
  background-color: #1A051D !important;
  box-shadow: none !important;
}
.selected{
  color: #f5eeee;
  background: #da7979;
  border-color: white;
}
.btn-selected{
  background: #FFFFFF;
  color: black;
}
